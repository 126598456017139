import "utils/wdyr";
import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "ui";
import { CacheProvider, EmotionCache } from "@emotion/react";
import createEmotionCache from "@/config/createEmotionCache";
import "@/styles/global.css";

const queryClient = new QueryClient();
const clientSideEmotionCache = createEmotionCache();
export interface AppPropsWithEmotionCache extends AppProps {
  emotionCache?: EmotionCache;
}

const App = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: AppPropsWithEmotionCache): JSX.Element => (
  <ErrorBoundary>
    <CacheProvider value={emotionCache}>
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
      </QueryClientProvider>
    </CacheProvider>
  </ErrorBoundary>
);

export default App;
