import classNames from "classnames";
import { useEffect } from "react";
import styles from "./adContainer.module.scss";

interface AdContainerProps {
  width: number;
  height: number;
  slotId: string;
}

export const AdContainer = ({
  width,
  height,
  slotId,
}: AdContainerProps): JSX.Element => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      // fire event to load ads
      const evt: CustomEvent = new CustomEvent('mantisCategoryAdLoad', {
        detail: { slotId },
      });
      document.body.dispatchEvent(evt);
    }
  }, [slotId]);
  const dimensions = {
    width: `${width}px`,
    height: `${height}px`,
    backgroundColor: "#f0f5f3",
    margin: "15px auto",
  };
  return (
    <div
      style={dimensions}
      id={slotId}
      className={classNames(styles["pioneer-ad"], "section-theme-pioneer", "pioneer-ad")}
      data-testid="ad-container"
    ></div>
  );
};
