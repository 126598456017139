import type { ReactNode } from "react";
import { Component } from "react";
import s from "./errorBoundary.module.scss";

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
  fallbackText?: string;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error): ErrorBoundaryState {
    return { hasError: true };
  }
  
  render(): ReactNode {
    if (this.state.hasError) {
      return this.props.fallback ?? (
        <div className={s.errorBoundary}>
          <h2>{this.props.fallbackText ?? "Oops! Something went wrong"}</h2>
        </div>
      );
    }

    return this.props.children;
  }
}
